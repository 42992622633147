<template>
  <div style="padding-left: 1rem;">
    <span class="col2_head"
      ><router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div></span
    >
    <v-table :Data="tableData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Table from '@/components/Table'

export default {
  computed: {
    ...mapGetters(['notifications']),
  },
  data() {
    return {
      tableData: {
        options: {
          spread: true,
          minimizeHead: true,
          hotRealodBody: true,
        },
        head: [
          {
            title: 'NR.',
            minimize: 2,
            sort: true
          },
          { title: 'Data și ora' },
          { title: 'Status' },
          { title: '' },
          { title: 'Mesajul' },
        ],
        body: [],
      },
    }
  },
  watch: {
    notifications: {
      handler() {
        this.syncData()
      },
      deep: true,
    },
  },
  methods: {
    prepareData(state) {
      const res =
        (state === undefined
          ? this.notifications
          : this.notifications.filter(e => e.isRead == state)
        )?.map((e, i) =>
          e
            ? [
                i + 1,
                this.toDateAndTime(e.createdAt),
                e.isRead ? 'Citit' : 'Necitit',
                '',
                e.message,
              ]
            : []
        ) || []
      return res.length ? res : [false]
    },
    syncData() {
      this.tableData.body = this.prepareData()
    },
  },
  created() {
    this.syncData()
  },
  components: {
    'v-table': Table,
  },
}
</script>
